import { isRobot } from '@/js/helpers/robot';
import { parseJobOfferContent } from '@/js/helpers/jobOffer';
import { sentryCaptureException } from '@/js/helpers/sentry';
import jobOffersService from '../../services/job-offers';

const mutations = {
  hasReviews (stateObj, has) {
    stateObj.hasReviews = has;
  },

  setJobOffer (stateObj, offer) {
    parseJobOfferContent(offer);
    stateObj.jobOffer = offer;
  },

  setEarning (stateObj, earnings) {
    stateObj.avgSalary = earnings.avg;
    stateObj.salarySuppliersNumber = earnings.count;
    stateObj.currency = earnings.currency;
  },

  setSignupLengths (stateObj, data) {
    stateObj.signupsLengthsNumber = data.count;
    stateObj.signupLengths = data.percents;
  },

  setOfferAvailability (stateObj, data) {
    stateObj.customerAvailability = data.percents;
    stateObj.availabilityNumberOfUsers = data.count;
  },

  setCost (stateObj, data) {
    stateObj.cost = data.avg;
    stateObj.costsNumber = data.count;
    stateObj.currency = data.currency;
  },

  setEquipment (stateObj, equipment) {
    stateObj.equipment = equipment;
  },

  setServices (stateObj, services) {
    stateObj.services = services;
  },

  setIsLoadingJobOffer (stateObj, isLoading) {
    stateObj.isLoadingJobOffer = isLoading;
  },

  setIsLoadingEquipment (stateObj, isLoading) {
    stateObj.isLoadingEquipment = isLoading;
  },

  setIsLoadingServices (stateObj, isLoading) {
    stateObj.isLoadingServices = isLoading;
  },
};

const actions = {
  hasReviews ({ commit }, has) {
    commit('hasReviews', has);
  },

  getJobOffer: async ({ commit }, { citySlug, partnerSlug }) => {
    commit('setIsLoadingJobOffer', true);

    let jobOffer = null;

    if (prePopulated?.jobOffer) {
      jobOffer = prePopulated.jobOffer;
      prePopulated.jobOffer = undefined;
    }

    try {
      const jobOfferResponse = await jobOffersService.getOffer({
        city_slug: citySlug,
        partner_slug: partnerSlug,
        review_partner_country: 1,
        test_call_source: 'jobOfferStoreModule',
      });
      jobOffer = jobOfferResponse;
    } catch (error) {
      sentryCaptureException(error);
      console.error('Error fetching job offer:', error);
    } finally {
      commit('setJobOffer', jobOffer);
      commit('setIsLoadingJobOffer', false);
    }
  },

  async getEarning ({ commit }, { jobOfferId }) {
    if (isRobot()) {
      return;
    }
    commit('setEarning', await jobOffersService.getEarning(jobOfferId));
  },

  async addEarning ({ commit }, data) {
    commit('setEarning', await jobOffersService.addEarning(data));
  },

  async getSignupLengths ({ commit }, { jobOfferId }) {
    if (isRobot()) {
      return;
    }
    commit('setSignupLengths', await jobOffersService.getSignupLengths(jobOfferId));
  },

  async addSignupLength ({ commit }, data) {
    commit('setSignupLengths', await jobOffersService.addSignupLength(data));
  },

  async getCustomerAvailability ({ commit }, { jobOfferId }) {
    if (isRobot()) {
      return;
    }
    commit('setOfferAvailability', await jobOffersService.getOfferAvailability(jobOfferId));
  },

  async addCustomerAvailability ({ commit }, data) {
    commit('setOfferAvailability', await jobOffersService.addOfferAvailability(data));
  },

  async addCost ({ commit }, data) {
    commit('setCost', await jobOffersService.addCost(data));
  },

  async getEquipment ({ commit }, jobOfferId) {
    commit('setIsLoadingEquipment', true);
    commit('setEquipment', await jobOffersService.getEquipment(jobOfferId));
    commit('setIsLoadingEquipment', false);
  },

  async getServices ({ commit }, jobOfferId) {
    commit('setIsLoadingServices', true);
    commit('setServices', await jobOffersService.getServices(jobOfferId));
    commit('setIsLoadingServices', false);
  },

  clearProducts ({ commit }) {
    commit('setEquipment', []);
    commit('setServices', []);
  },
};

const state = {
  jobOffer: null,
  hasReviews: false,
  avgSalary: null,
  salarySuppliersNumber: null,
  currency: null,
  signupsLengthsNumber: null,
  signupLengths: null,
  customerAvailability: null,
  availabilityNumberOfUsers: null,
  cost: null,
  costsNumber: null,
  equipment: [],
  services: [],
  isLoadingJobOffer: false,
  isLoadingEquipment: false,
  isLoadingServices: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
