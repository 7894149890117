import jobOffersService from '@/js/services/job-offers';
import { parseJobOfferContent } from '@/js/helpers/jobOffer';

const mutations = {
  startLoadingOffers (rootState) {
    rootState.isLoading = true;
  },

  stopLoadingOffers (stateObj) {
    stateObj.isLoading = false;
  },

  startLoadingMyOffers (rootState) {
    rootState.isLoadingMyOffers = true;
  },

  stopLoadingMyOffers (stateObj) {
    stateObj.isLoadingMyOffers = false;
  },

  setOffers (stateObj, jobOffers) {
    if (jobOffers.length) {
      stateObj.jobOffers = jobOffers.map(parseJobOfferContent);
    } else {
      stateObj.jobOffers = [];
    }
  },

  setMyOffers (stateObj, jobOffers) {
    stateObj.myJobOffers = jobOffers;
  },

  setMyOffersLoaded (stateObj) {
    stateObj.isMyOffersLoaded = true;
  },

  setMatchedOffers (stateObj, jobOffers) {
    stateObj.matchedJobOffers = jobOffers.map(parseJobOfferContent);
  },

  clearMatchedOffers (stateObj) {
    stateObj.matchedJobOffers = [];
  },

  setFilteredCategories (stateObj, categories) {
    stateObj.filteredCategories = categories;
  },

  filterCategories (stateObj, categoryId) {
    let { filteredCategories } = stateObj;

    if (categoryId) {
      if (filteredCategories.indexOf(categoryId) === -1) {
        filteredCategories.push(categoryId);
      } else {
        filteredCategories.splice(filteredCategories.indexOf(categoryId), 1);
      }
    } else {
      filteredCategories = [];
    }

    filteredCategories.sort((a, b) => parseInt(a) - parseInt(b));

    stateObj.filteredCategories = filteredCategories;
  },

  clearFilteredCategories (stateObj) {
    stateObj.filteredCategories = [];
  },

  setFilteredType (stateObj, type) {
    stateObj.filteredType = type;
  },

  clearFilteredType (stateObj) {
    stateObj.filteredType = null;
  },
};

const actions = {
  async getOffers ({ commit }, params) {
    if (!params) {
      return;
    }

    if (prePopulated && prePopulated.jobOffers) {
      prePopulated.jobOffers = undefined;
    }

    commit('startLoadingOffers');
    const offers = await jobOffersService.getOffers(params);
    commit('setOffers', offers);
    commit('stopLoadingOffers');
  },

  async getMyJobOffers ({ commit }) {
    commit('startLoadingMyOffers');
    commit('setMyOffers', await jobOffersService.getMyOffers());
    commit('stopLoadingMyOffers');
    commit('setMyOffersLoaded');
  },

  async getMatchedJobOffers ({ commit }, params) {
    const offers = await jobOffersService.getOffers(params);
    commit('setMatchedOffers', offers);
  },

  filterByCategory ({ commit }, categoryId) {
    commit('filterCategories', categoryId);
  },

  clearMatchedOffers ({ commit }) {
    commit('clearMatchedOffers');
  },

  setFilteredCategories ({ commit }, categories) {
    commit('setFilteredCategories', categories);
  },

  clearFilteredCategories ({ commit }) {
    commit('clearFilteredCategories');
  },

  setFilteredType ({ commit }, type) {
    commit('setFilteredType', type);
  },

  clearFilteredType ({ commit }) {
    commit('clearFilteredType');
  },
};

const getters = {
  notMyJobOffers (stateObj) {
    return stateObj.jobOffers
      .filter(jobOffer => (stateObj.myJobOffers
        .findIndex(myJobOffer => myJobOffer.id === jobOffer.id)
      ) === -1) || [];
  },

  countMatchedOffers (stateObj) {
    return stateObj.matchedJobOffers.length;
  },
};

const state = {
  isLoading: false,
  jobOffers: [],
  myJobOffers: [],
  matchedJobOffers: [],
  filteredCategories: [],
  filteredType: null,
  isMyOffersLoaded: false,
  isLoadingMyOffers: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
