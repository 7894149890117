import superagent from 'superagent';
import { translate } from '@/js/helpers/i18n';
import store from '../store';

const addressWebApi = process.env.VUE_APP_WEB_API_URL;
const addressSalesApi = process.env.VUE_APP_SALES_API_URL;
const ofpApiBaseUrl = process.env.VUE_APP_OFP_API_URL;
const tryLaterMessage = 'Please try again later or contact us at help@appjobs.com.';

const defaultOpts = {
  type: 'json',
  authorize: true,
  data: {},
  silenceErrors: false,
  errorMessage: null,
  sendSessionId: false,
};

const getLanguage = () => store.state.i18n.language;

const getHeaders = (opts = {}) => {
  const headers = opts.headers || {};

  if (opts.authorize) {
    const { idToken } = store.state.auth;

    if (idToken) {
      headers.Authorization = `Bearer ${idToken}`;
    }
  }

  return headers;
};

const resolveApiUrl = (opts, url) => {
  let urlCombined;

  switch (opts.target) {
    case 'external':
      urlCombined = url;
      break;
    case 'sales-api':
      urlCombined = `${addressSalesApi}${url}`;
      break;
    case 'ofp-api':
      urlCombined = `${ofpApiBaseUrl}${url}`;
      break;
    default:
      urlCombined = `${addressWebApi}/${getLanguage()}${url}`;

      if (opts.case_sensitive !== true) {
        urlCombined = urlCombined.toLowerCase();
      }
      break;
  }

  return urlCombined;
};

/**
 * HTTP request
 *
 * Options:
 *
 *   + type
 *   + authorize (bool, default: true) - include authorization header
 *   + external
 *   + data (array)
 *   + params - Object will be converted to query string
 *   + silenceErrors
 *   + errorMessage
 *   + serialize - custom serialization function
 */
export default function request (method, url, opts = {}) {
  const localOpts = { ...defaultOpts, ...opts };
  const urlCombined = resolveApiUrl(localOpts, url);

  if (localOpts.sendSessionId === true) {
    localOpts.data.pageSessionId = store.getters.userSessionId;
  }

  function handlePromise (resolve, reject) {
    function setRequest () {
      req = superagent[method](urlCombined)
        .accept(localOpts.type)
        .set(getHeaders(localOpts))
        .query(opts.params);
      if (localOpts.serialize) {
        req = req
          .serialize(localOpts.serialize);
      }
      if (localOpts.type) {
        req.type(localOpts.type);
      }
    }

    function sendRequest () {
      req
        .send(localOpts.data)
        .timeout({
          response: 45000, // wait for the server to start sending
          deadline: 90000, // wait for the file to finish loading
        })
        .end(processResponse);
    }

    function processResponse (error, response) {
      if (!error) {
        resolve(response.body || response.text);
        return;
      }

      if (!localOpts.silenceErrors) {
        if (error.timeout) {
          store.dispatch('setMessage', {
            message: `Request timeout. ${tryLaterMessage}`,
            title: 'Error',
            variation: 'error',
          });
        } else if (localOpts.authorize
          && (response && [401, 403].includes(response.status))) {
          store.dispatch('auth/logout');
          store.dispatch('setMessage', {
            message: `${response.statusText}. ${translate('auth.errors.sign_in')}`,
            title: 'Error',
            variation: 'error',
          });
        } else {
          if (error.status === 404) {
            resolve([]);

            return;
          }

          let { errorMessage } = localOpts;
          if (!errorMessage) {
            errorMessage = 'Cannot load data. ';

            if (error.status) {
              errorMessage += `Request error #${error.status}. `;
            }
          }
          if (error.status !== 422) {
            store.dispatch('setMessage', {
              message: `${errorMessage} ${tryLaterMessage}`,
              title: 'Error',
              variation: 'error',
            });
          }
        }
      }
      reject(error);
    }

    let req;

    setRequest();
    sendRequest();
  }

  return new Promise(handlePromise);
}

export function get (url, opts = {}) {
  return request('get', url, opts);
}

export function post (url, opts = {}) {
  return request('post', url, opts);
}

export function patch (url, opts = {}) {
  return request('patch', url, opts);
}

export function head (url, opts = {}) {
  return request('head', url, opts);
}

export function del (url, opts = {}) {
  return request('delete', url, opts);
}

export function put (url, opts = {}) {
  return request('put', url, opts);
}
