const { UNDEFINED_IN_URL_ERROR, JOB_OFFER_404_ERROR } = require('../enums/errors');
const { isRobot } = require('./robot');

const getSentry = () => import('@sentry/browser');

function sentryCaptureException (error) {
  getSentry().then(sentryModule => {
    sentryModule.captureException(error);
  });
}

class CustomSentryError extends Error {
  constructor (errorName, message) {
    super(message);
    this.name = errorName;
  }
}

function sendUndefinedInUrlErrorMessage (Sentry, error, destinationUrl, referer = '') {
  Sentry.withScope(scope => {
    scope.setLevel('warning');
    scope.setTag('destinationUrl', destinationUrl);
    if (referer) {
      scope.setTag('referer', referer);
    }
    Sentry.captureException(error);
  });
}

function catchUndefinedInUrlError (destinationUrl, request, referer = '') {
  const userAgent = request?.headers['user-agent'];
  const headers = request?.headers;

  if (isRobot(userAgent, headers)) {
    return false;
  }

  getSentry().then(sentryModule => {
    sendUndefinedInUrlErrorMessage(
      sentryModule,
      new CustomSentryError(UNDEFINED_IN_URL_ERROR, 'The "undefined" part was found in the URL'),
      destinationUrl,
      referer,
    );
  });

  return true;
}

function catchJobOffer404Error (request) {
  const userAgent = request?.headers['user-agent'];
  const headers = request?.headers;

  if (isRobot(userAgent, headers)) {
    return false;
  }

  sentryCaptureException(new CustomSentryError(JOB_OFFER_404_ERROR, 'Job offer endpoint returned 404'));

  return true;
}

module.exports = {
  catchUndefinedInUrlError,
  catchJobOffer404Error,
  sentryCaptureException,
};
