import cityCategoryService from '@/js/services/city-category';
import reviewsService from '../../services/reviews';

const mutations = {
  setId (stateObj, id) {
    stateObj.id = id;
  },

  setCity (stateObj, city) {
    stateObj.city = city;
  },

  setCategory (stateObj, category) {
    stateObj.category = category;
  },

  setHeader (stateObj, header) {
    stateObj.header = header;
  },

  setOverview (stateObj, overview) {
    stateObj.overview = overview;
  },

  setRating (stateObj, rating) {
    stateObj.rating = rating;
  },

  setRatingCount (stateObj, ratingCount) {
    stateObj.ratingCount = ratingCount;
  },

  setReviews (stateObj, reviews) {
    stateObj.reviews = reviews;
  },

  setCityCategoryInfo (stateObj, data) {
    stateObj.cityCategory = data;
  },

  startLoading (stateObj) {
    stateObj.isLoading = true;
  },

  stopLoading (stateObj) {
    stateObj.isLoading = false;
  },
};

const actions = {
  async setData ({ commit }, cityCategory) {
    commit('setId', cityCategory.id);
    commit('setCity', cityCategory.city);
    commit('setHeader', cityCategory.header);
    commit('setCategory', cityCategory.category);
    commit('setOverview', cityCategory.overview);
    commit('setRating', cityCategory.rating);
    commit('setRatingCount', cityCategory.ratingCount);
  },

  async getReviews ({ commit }, params) {
    commit('setReviews', await reviewsService.getReviewsForCityCategory(params.categoryId, params.cityId));
  },

  async getCityCategoryInfo ({ commit }, { citySlug, categorySlug }) {
    commit('startLoading');

    try {
      const cityCategories = await cityCategoryService.getInfo({
        city_slug: citySlug,
        category_slug: categorySlug,
      });
      const cityCategory = cityCategories?.[0];
      if (!cityCategory) {
        commit('setCityCategoryInfo', null);
      } else {
        commit('setCityCategoryInfo', {
          ...cityCategory,
        });
      }
    } catch (error) {
      console.error('Error fetching city category info:', error);
      commit('setCityCategoryInfo', null);
    }

    commit('stopLoading');
  },
};

const state = {
  id: null,
  category: {},
  city: {},
  header: null,
  overview: null,
  rating: null,
  ratingCount: null,
  reviews: [],
  isLoading: false,
  cityCategory: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
