import citiesService from '@/js/services/cities';
import reviewsService from '@/js/services/reviews';

const mutations = {
  setCityId (stateObj, id) {
    stateObj.id = id;
  },

  setCitySlug (stateObj, slug) {
    stateObj.slug = slug;
  },

  setCityOrgSlug (stateObj, orgSlug) {
    stateObj.orgSlug = orgSlug;
  },

  setCityName (stateObj, name) {
    stateObj.name = name;
  },

  setCityCountry (stateObj, country) {
    stateObj.country = country;
  },

  setCityLat (stateObj, lat) {
    stateObj.lat = lat;
  },

  setCityLng (stateObj, lng) {
    stateObj.lng = lng;
  },

  setHeader (stateObj, header) {
    stateObj.header = header;
  },

  setOverview (stateObj, overview) {
    stateObj.overview = overview;
  },

  setSeoDescription (stateObj, seoDescription) {
    stateObj.seoDescription = seoDescription;
  },

  setTopReviews (stateObj, reviews) {
    stateObj.topReviews = reviews || [];
  },

  startLoading (stateObj) {
    stateObj.isLoading = true;
  },

  stopLoading (stateObj) {
    stateObj.isLoading = false;
  },

  setRating (stateObj, rating) {
    stateObj.rating = rating;
  },

  setRatingCount (stateObj, ratingCount) {
    stateObj.ratingCount = ratingCount;
  },
};

const actions = {
  setCity ({ commit }, payload) {
    const {
      id,
      slug,
      orgSlug,
      name,
      country,
      header,
      overview,
      seoDescription,
      rating,
      ratingCount,
      latitude,
      longitude,
    } = payload;

    commit('setCityId', id);
    commit('setCitySlug', slug);
    commit('setCityOrgSlug', orgSlug);
    commit('setCityName', name);
    commit('setCityCountry', country);
    commit('setCityLat', latitude);
    commit('setCityLng', longitude);
    commit('setHeader', header);
    commit('setOverview', overview);
    commit('setSeoDescription', seoDescription);
    commit('setRating', rating);
    commit('setRatingCount', ratingCount);
  },

  async getCityBySlug ({ commit, dispatch }, { citySlug, expand }) {
    commit('startLoading');

    try {
      const city = await citiesService.getCityBySlug({ citySlug }, { expand });
      if (!city) {
        dispatch('setCity', null);
      } else {
        dispatch('setCity', {
          ...city,
        });
      }
    } catch (error) {
      console.error('Error fetching city info:', error);
      dispatch('setCity', null);
    }

    commit('stopLoading');
  },

  async getTopReviewsForCity ({ commit }, cityId) {
    commit('setTopReviews', await reviewsService.getLatestForCity(cityId));
  },
};

const state = {
  id: null,
  slug: null,
  orgSlug: null,
  name: null,
  country: null,
  header: null,
  overview: null,
  seoDescription: null,
  topReviews: [],
  isLoading: false,
  rating: null,
  ratingCount: null,
  lat: null,
  lng: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
